import "../Styles/login.scss";
import {
  Button,
  TextField,
  Typography,
  Link,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import logo from "../assets/imgs/logo.png";
import { useRef, useState, useEffect } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material"; // Import icons
import lg1 from "../assets/imgs/lg1.jpg";
import lg2 from "../assets/imgs/lg2.jpg";
import lg3 from "../assets/imgs/lg3.jpg";
import lg4 from "../assets/imgs/lg4.jpg";
import lg5 from "../assets/imgs/lg5.jpg";
import lg6 from "../assets/imgs/lg6.jpg";
import lg7 from "../assets/imgs/lg7.jpg";
import lg8 from "../assets/imgs/lg8.jpg";
import lg9 from "../assets/imgs/lg9.jpg";
import lg10 from "../assets/imgs/lg10.jpg";

const images = [lg2, lg9, lg3, lg4, lg5, lg6, lg7, lg8, lg1, lg10];

export default function Login() {
  const rfEmail = useRef();
  const rfPassword = useRef();
  const [isErr, setIsErr] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const [body, updateBody] = useState({
    Email: null,
    Password: null,
  });

  const login = () => {
    let d = body;
    d.Email = rfEmail.current.value.toLowerCase().trim();
    d.Password = rfPassword.current.value;
    updateBody(d);
    setIsErr("");

    if (!validateEmail(body.Email))
      return setIsErr("Please Enter a Valid Email Address!");
    if (!validatePassword(body.Password))
      return setIsErr("Password must be at least 6 characters!");

    if (validateEmail(body.Email) && validatePassword(body.Password)) {
      setIsLoading(true);
      fetch("/api/auth/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error("Login Failed");
          }
        })
        .then((data) => {
          if (data.success) {
            localStorage.setItem("gdfhgfhtkngdfhgfhtkn", data.token);
            setIsErr(data.success);
            setIsLoading(false);
            window.location.href = "/";
          } else {
            setIsLoading(false);
            setIsErr(data.error);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setIsErr("Login failed");
        });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  return (
    <Box className="login">
      <Box
        className="background-slider"
        style={{ transform: `translateX(-${currentIndex * 10}%)` }}
      >
        {images.map((img, index) => (
          <img key={index} src={img} alt={`Background ${index}`} />
        ))}
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            width: { xs: 320, sm: 400, md: 450 },
            p: 3,
            boxShadow: 3,
            borderRadius: 2,
            bgcolor: "background.paper",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" gutterBottom align="center">
            BROOKE EAST AFRICA
          </Typography>
          <Typography variant="h6" gutterBottom align="center">
            GIS Data Portal
          </Typography>
          <Box sx={{ mb: 2 }}>
            <img src={logo} alt="Logo" style={{ width: 100, height: 100 }} />
          </Box>
          <Typography color="error" align="center">
            {isErr}
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <TextField
              inputRef={rfEmail}
              label="Email Address"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              required
            />
            <Box sx={{ position: "relative", width: "100%" }}>
              <TextField
                sx={{
                  width: { xs: 300, sm: 350, md: 400 },
                }}
                inputRef={rfPassword}
                label="Password"
                variant="outlined"
                fullWidth
                margin="normal"
                type={showPassword ? "text" : "password"}
                required
              />
              <IconButton
                onClick={() => setShowPassword((prev) => !prev)}
                sx={{
                  position: "absolute",
                  right: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "action.active",
                  zIndex: 1,
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}{" "}
              </IconButton>
            </Box>

            <Box sx={{ textAlign: "right", mb: 2 }}>
              <Link href="#" underline="hover">
                Forgot password?
              </Link>
            </Box>
            <Button
              onClick={login}
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mb: 2 }}
            >
              Login
            </Button>
          </form>
          {loading && (
            <CircularProgress size={24} sx={{ display: "block", mx: "auto" }} />
          )}
        </Box>
      </Box>
    </Box>
  );
}
